#dendrogram-header {
  width: 100%;
  height: 30px;
  background: url(../../images/dendrogram_hd.gif);
}

#dendrogram-content {
  position: relative;
  width: 355px;
  height: calc(100% - 38px);
  padding: 20px;
  background: #eee url(../../images/dendrogram_bg.gif) repeat-y;
  z-index: 2;
}
