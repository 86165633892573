@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css');
@import url('https://use.fontawesome.com/releases/v5.6.1/css/all.css');
:root {
  font-size: 10px;
}

body {
  overflow-x: hidden;
  font-family: 'Noto Sans JP', serif;
  font-size: 1.4rem;
  color: #4a5261;
  background-color: #f4f8ff;
}

.wrapper {
  min-height: calc(100vh - 6rem);
  margin-top: 6rem;
}
.mb-last {
  margin-bottom: 10rem;
}

.bg-blue {
  background-color: #244789 !important;
}

.bg-gray {
  background-color: #f1f4f9 !important;
}

.bg-white {
  background-color: #fff !important;
}

.border-blue {
  border-color: #244789 !important;
}

.color-black {
  color: #4a5261;
}

section {
  padding: 1.5rem 3rem;
}

main {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 60px);
  margin: 0 auto;
  padding: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2.5rem 3rem;
  border-top: 1px solid #dee1e7;
  background-color: #f4f8ff;
}

.navbar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 6rem;
  padding: 2rem;
}
.navbar-brand {
  display: flex;
  align-items: baseline;
}
.navbar-brand img {
  width: 8rem;
  height: 1.8rem;
  margin-right: 1rem;
}
.navbar-brand span {
  position: relative;
  top: -0.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}
.navbar-login {
  position: relative;
  margin-left: auto;
  color: #fff;
}
.navbar-toggler-icon {
  color: white !important;
}

.sidebar {
  margin-left: -26rem;
  margin-right: 26rem;
  transition: margin 0.15s ease-out;
  background-color: #dee8fb;
  z-index: 1;
}
@media (min-width: 768px) {
  .sidebar {
    min-width: 26rem;
    margin-left: 0;
    margin-right: 0;
  }
}
.sidebar-toggle {
  position: absolute;
  margin-left: 0;
  margin-right: 0;
  width: 26rem;
  height: 100%;
}
.sidebar-toggle-visible {
  margin-left: 0;
}
.sidebar-rounded-top-right {
  border-top-right-radius: 0.25rem;
}
.sidebar-menu {
  width: 26rem;
  position: fixed;
}
.sidebar-icon img {
  position: relative;
  top: -0.3rem;
  width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;
}
.sidebar-text {
  position: relative;
  font-size: 1.6rem;
}

.breadcrumb {
  padding: 1.5rem 3rem;
  background-color: #f4f8ff;
  border-bottom: 1px solid #dee1e7;
}
.breadcrumb-item + .breadcrumb-item::before {
  position: relative;
  top: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  content: url('~images/icon-arrow-right.svg');
  opacity: 0.35;
}
.breadcrumb a {
  color: #4a5261;
  text-decoration: none;
}

h1 {
  font-size: 2.4rem;
  font-weight: 900;
}
h1 span {
  font-size: 1.6rem;
}

.color-black {
  color: #4a5261;
}

.color-gray {
  color: #a1a6ab;
}

.color-blue {
  color: #244789;
}

.color-danger {
  color: #842029;
}

img {
  max-width: 100%;
  height: auto;
}

.icon {
  width: 1.6rem;
}

i.fas {
  color: #4a5261;
}

.btn {
  margin: 0.25rem 0.5rem;
  padding: 1rem 1.5rem;
  border-color: #ced4da;
  border-radius: 0.35rem;
  color: #4a5261;
  font-size: 1.4rem;
}
.btn:hover {
  background-color: rgba(51, 150, 252, 0.05);
}
.btn-rounded {
  border-radius: 3rem;
}

.btn-register {
  margin: 0.25rem 0.5rem;
  padding: 1rem 1.5rem;
  min-width: 12rem;
  background-color: #244789;
  border-color: #244789;
  border-radius: 0.35rem;
  color: #fff;
  font-size: 1.4rem;
}
.btn-register:hover {
  background-color: #173264;
  color: #fff;
}
.btn-register-lg {
  padding: 1.5rem 2.5rem;
  min-width: 20rem;
  font-size: 1.6rem;
}
.btn-register i {
  margin-right: 1rem;
  color: #fff;
}
.btn-register-outline {
  background-color: #fff;
  color: #244789;
}
.btn-register-outline:hover {
  background-color: #dee8fb;
  color: #244789;
}

.btn-cancel {
  min-width: 12rem;
}

.btn-top-item {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 1.5rem;
  padding: 2rem 5.5rem 2rem 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;
}
.btn-top-item img {
  position: relative;
  width: 1.6rem;
  margin-right: 1.5rem;
  opacity: 0.65;
}
.btn-top-item::after {
  content: url('~images/icon-arrow-right-line.svg');
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 6rem;
  height: 100%;
  transition: all 0.3s;
}
.btn-top-item:hover::after {
  padding-left: 0.5rem;
}

.btn-back-to-top {
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  background-color: #244789;
  z-index: 1;
  color: #fff;
  font-size: 1.6rem;
}
.btn-back-to-top:hover {
  background-color: #173264;
  transition: all 0.3s;
}
.btn-back-to-top i {
  color: #fff;
}

.btn-control {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border: 1px solid #ced4da;
  font-size: 1.2rem;
  font-weight: 500;
}
.btn-control:hover {
  background-color: rgba(222, 232, 251, 0.35);
}
.btn-control i {
  padding-right: 1rem;
}

.btn-outline-blue {
  margin: 0.25rem 0.5rem;
  padding: 1rem 1.5rem;
  border-color: #244789;
  border-radius: 0.35rem;
  color: #244789 !important;
}
.btn-outline-blue:hover {
  background-color: rgba(222, 232, 251, 0.35);
}

.nav-item {
  position: relative;
  margin-top: 1.4rem;
}
.nav-item-child {
  margin-top: 0;
}
.nav-item-child .nav-link {
  padding: 1.2rem 5.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.nav-item-child .nav-link .sidebar-text {
  font-size: 1.4rem;
}
.nav-item.active > .nav-link {
  background-color: rgba(51, 150, 252, 0.1);
}

.nav-link {
  padding: 1.4rem 2rem;
  color: #4a5261 !important;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
}
.nav-link:hover {
  background-color: rgba(51, 150, 252, 0.1);
}

.link-arrow {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5rem;
  transition: all 0.2s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  cursor: pointer;
}
.link-arrow:hover {
  opacity: 0.75;
}

.collapsed.link-arrow {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.form-control {
  display: inline-block;
  position: relative;
  width: auto;
  padding: 0.75rem 1.5rem;
  border: 1px solid #ced4da;
  font-size: 1.2rem;
  font-weight: 500;
}
.form-control:hover {
  background-color: rgba(222, 232, 251, 0.35);
}
.form-control-lg {
  min-width: 23.5rem;
  padding: 1rem 1.75rem;
  font-size: 1.4rem;
}
.form-control-sm {
  width: 8rem !important;
  padding: 1rem 1.75rem;
  font-size: 1.4rem;
}
.form-control::-webkit-input-placeholder {
  color: #a1a6ab;
}
.form-control::placeholder {
  color: #a1a6ab;
}
.form-control:disabled {
  pointer-events: none;
}

.form-select {
  padding-right: 3rem;
  background-image: url('~images/icon-caret-down.svg');
  background-size: 1rem;
  background-position: right 1rem center;
}

.form-check-input {
  width: 2rem;
  height: 2rem;
  margin-top: 0.05rem;
  margin-right: 1rem;
}
.form-check-input:checked {
  background-color: rgba(36, 71, 137, 0.9);
  border-color: rgba(36, 71, 137, 0.9);
}

@media (min-width: 768px) {
  .form-outline {
    position: relative;
    display: flex;
  }
}

.form-outline .form-label {
  display: block;
  margin-right: 2rem;
  padding-top: 1rem;
  text-align: left;
}
@media (min-width: 768px) {
  .form-outline .form-label {
    width: 10rem;
    text-align: right;
  }
}

.label {
  display: inline-block;
  margin-bottom: 0.75rem;
  font-weight: 700;
}
.label-lg {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
}

.label-nessesary {
  position: relative;
  top: -0.1rem;
  margin-left: 0.75rem;
  padding: 0.25rem 0.75rem;
  border: 1px solid #d63384;
  border-radius: 0.2rem;
  color: #d63384;
  font-size: 1rem;
  font-weight: 500;
}

.text-notice {
  margin: 0.25rem 0.5rem;
  font-size: 1.2rem;
  color: #a1a6ab;
}

ul.line {
  padding-left: 0;
  list-style: none;
}
ul.line li {
  padding: 1.5rem 0.75rem;
  border-bottom: 1px solid #ced4da;
}
ul.line li.line-title {
  padding: 1.5rem 2.5rem;
  margin: -1rem -2.5rem 0.75rem;
  background-color: #dee8fb;
  border: 1px solid #dee8fb;
  border-radius: 0.75rem 0.75rem 0 0;
}
ul.line li.line-header {
  padding-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
}
ul.line li.line-none {
  border-bottom: none;
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 546px;
  }
}

.modal-dialog .modal-content {
  border-radius: 0.35rem;
  box-shadow: 0 0 5rem rgba(0, 0, 0, 0.25);
}

.modal-header {
  padding: 1rem 2rem 1.4rem;
  background-color: #244789 !important;
  border-radius: 0;
  color: white;
}
.modal-header .btn-close {
  background-image: url('~images/icon-cross.svg');
  background-size: 2.4rem;
}

.modal-lg {
  max-width: 800px;
}

.modal-body {
  padding: 2rem 3rem 2rem 2rem;
}

.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eee;
  background-color: #fafafa;
}

.modal-footer {
  padding: 1rem;
}

.modal-title {
  font-size: 1.6rem;
}
.modal-title i {
  padding-right: 1rem;
  color: #fff;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  right: -960px;
  width: 960px;
  height: 100%;
  max-width: none;
  background-color: #fff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
.modal.right .modal-dialog .modal-content {
  border-radius: 0;
  box-shadow: none;
}

.modal.right.show .modal-dialog {
  right: 0;
}

#graph-modal {
  display: contents !important;
}
#graph-modal.show {
  display: block !important;
}

.dropdown-menu {
  width: 18rem;
  top: 3rem;
  right: 0;
  padding: 1rem;
  font-size: 1.4rem;
}
.dropdown-menu img {
  position: relative;
  top: -0.15rem;
  width: 2.2rem;
  padding-right: 0.75rem;
}

li.dropdown-item {
  display: block;
  padding: 0.5rem 1rem;
}

div.dropdown-toggle::after {
  margin-left: 0.5rem;
}

.copyright {
  font-size: 1.2rem;
  text-align: right;
}

.page-control {
  display: flex;
  justify-content: flex-end;
  margin: 1rem auto;
}
.page-control-result {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  padding-left: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}
.page-control-result span {
  position: relative;
  top: -0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 2.4rem;
  font-weight: 700;
}
.page-control-result input[type='text'] {
  position: relative;
  top: -0.1rem;
  margin: auto 1rem;
  border: none;
  background: none;
  width: 12rem;
  text-align: right;
  font-size: 2.4rem;
  font-weight: 700;
  color: #4a5261;
  pointer-events: none;
}

.page-content {
  margin: 2rem auto 8rem;
  padding-bottom: 4rem;
}
.page-content-header {
  display: flex;
  border-radius: 0.35rem;
}
.page-content-body {
  margin-top: 1rem;
  padding: 1rem 2.5rem 2rem;
  background-color: #fff;
  border: 1px solid #dee8fb;
  border-radius: 0.75rem;
}

.page-index {
  margin-top: 2.5rem;
}
.page-index-item {
  display: block;
  position: relative;
  padding: 2rem 8.5rem 2rem 2.5rem;
  height: 100%;
  background-color: #fff;
  border: 1px solid #dee8fb;
  border-radius: 0.75rem;
  text-decoration: none;
  color: #4a5261 !important;
  cursor: pointer;
}
.page-index-item::after {
  content: url('~images/icon-arrow-right-line.svg');
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 4.5rem;
  height: 100%;
  background-color: #dee8fb;
  border-radius: 0 0.75rem 0.75rem 0;
  transition: all 0.3s;
}
.page-index-item:hover {
  background-color: rgba(222, 232, 251, 0.01);
}
.page-index-item:hover::after {
  padding-left: 0.5rem;
}
.page-index-item h2 {
  margin-bottom: 1rem;
  font-size: 1.75rem;
  font-weight: 700;
}
.page-index-item h2 img {
  position: relative;
  top: -0.125rem;
  width: 2rem;
  margin-right: 1.5rem;
  opacity: 0.65;
}

.top-item {
  margin: 1.5rem auto;
  padding: 1.5rem 2.5rem;
  background-color: #fff;
  border: 1px solid #dee8fb;
  border-radius: 0.75rem;
  text-decoration: none;
  color: #4a5261 !important;
}
.top-item-link {
  text-decoration: none;
}
.top-item h2 {
  margin: 1rem auto 2rem;
  padding-left: 3rem;
  background-size: 1.6rem;
  background-position: left 4px;
  background-repeat: no-repeat;
}

.top-information {
  position: relative;
}
.top-information-control {
  position: absolute;
  top: 2.5rem;
  right: 3rem;
  width: 2rem;
  height: 2rem;
}
.top-information-control img {
  width: 2rem;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: all 0.3s;
}
.top-information-control.active img {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.top-information-list {
  list-style: none;
  padding: 0;
  border-top: 1px solid rgba(206, 212, 218, 0.3);
}
.top-information-list li {
  display: flex;
  width: 100%;
  padding: 1rem 0.75rem;
  border-bottom: 1px solid rgba(206, 212, 218, 0.3);
}
.top-information-date {
  margin: 0 1.5rem 0 0.25rem;
  color: #a1a6ab;
}
.top-information-text a {
  color: #4a5261;
}
.top-information h2 {
  background-image: url('~images/icon-Information.svg');
}

.top-about-box-text p {
  padding: 0 1rem;
  font-size: 1.6rem;
  line-height: 1.65;
}

.top-about-box-movie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.top-about-box-movie iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.top-about h2 {
  background-image: url('~images/icon-light.svg');
}

.top-stress h2 {
  background-image: url('~images/icon-meter.svg');
}

.top-organize h2 {
  background-image: url('~images/icon-diagnose.svg');
}

.top-group h2 {
  background-image: url('~images/icon-group.svg');
}

.top-department h2 {
  background-image: url('~images/icon-network.svg');
}

.top-user h2 {
  background-image: url('~images/icon-user.svg');
}

.top-advice h2 {
  background-image: url('~images/icon-comment.svg');
}

.group-list-control {
  width: 4rem;
}

.group-list-grid {
  width: calc(100% - 36rem);
  display: flex;
  align-items: center;
}

.group-list-id {
  display: flex;
  align-items: center;
  margin: auto 10px;
  width: 25%;
  word-break: break-all;
}
.group-list-id i {
  display: block;
  margin-right: 1.4rem;
  cursor: pointer;
  color: #3396fc;
}
.group-list-id i:hover {
  color: #217cd9;
}

.group-list-name {
  margin: auto 10px;
  width: 25%;
}

.group-list-comment {
  margin: auto 10px;
  width: 50%;
}

.group-list-buttons {
  margin-left: auto;
  width: 22rem;
  text-align: center;
}

.group-user-add-control,
.group-user-division-add-control {
  display: block;
  margin: 1rem auto;
  cursor: pointer;
}
.group-user-add-control:hover,
.group-user-division-add-control:hover {
  opacity: 0.9;
}
.group-user-add-control i,
.group-user-division-add-control i {
  margin-right: 1rem;
  transition: all 0.3s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.group-user-add-control.active i,
.group-user-division-add-control.active i {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.group-user-add-content,
.group-user-division-add-content {
  display: none;
  margin-bottom: 2rem;
}
.group-user-add-content-header,
.group-user-division-add-content-header {
  display: flex;
  border-radius: 0.35rem;
}
.group-user-add-content-body,
.group-user-division-add-content-body {
  margin-top: 1rem;
}

.department-list-control {
  width: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}
.department-list-control i {
  transition: all 0.3s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.department-list-control.active i {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.department-list-control.no-control {
  cursor: none;
}

.department-list-title {
  font-size: 1.6rem;
  font-weight: 700;
}
.line-title p {
  padding-left: 3rem;
  font-size: 1.6rem;
  font-weight: 700;
}
.department-list-text {
  font-size: 1.4rem;
}

.department-list-code {
  padding-left: 1rem;
  color: #a1a6ab;
  font-size: 1.2rem;
}

.department-list-buttons {
  margin-left: auto;
  width: 18rem;
  text-align: center;
}

.department-list-child {
  display: none;
}
.department-list-child .department-list-text {
  padding-left: 2rem;
}

.department-list-child01 .department-list-control {
  padding-left: 2rem;
}

.department-list-child02 .department-list-control {
  padding-left: 4rem;
}

.department-list-child03 .department-list-control {
  padding-left: 8rem;
}

.department-list-child04 .department-list-control {
  padding-left: 10rem;
}

.department-list-child05 .department-list-control {
  padding-left: 12rem;
}

.department-list-child06 .department-list-control {
  padding-left: 14rem;
}

.department-list-child07 .department-list-control {
  padding-left: 16rem;
}

.department-list-child08 .department-list-control {
  padding-left: 18rem;
}

.department-list-child09 .department-list-control {
  padding-left: 20rem;
}

.department-manager-id {
  width: 6rem;
  font-weight: 700;
  text-align: center;
}

.department-manager-class {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 700;
}

.department-manager-buttons {
  margin-left: auto;
  width: 18rem;
  text-align: center;
}

.user-information {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 6rem;
  margin: 2rem auto;
  padding: 0 3.5rem;
  background-color: #dee8fb;
  border-radius: 0.35rem;
  text-align: center;
}
.user-information-id,
.user-information-name {
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.75rem;
  font-weight: 500;
}

.table-user-list th,
.table-user-list td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
  text-align: center;
}
.table-user-list th:first-child,
.table-user-list td:first-child {
  padding-left: 1rem;
}
.table-user-list th:last-child,
.table-user-list td:last-child {
  padding-right: 1rem;
}
.table-user-list th span,
.table-user-list td span {
  display: inline-block;
  margin: 0 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: rgba(36, 71, 137, 0.05);
  border-radius: 0.25rem;
}

.table-user-list-department td {
  height: 6rem;
}

.table-user-list-type {
  background-color: none !important;
}

.table-user-list-link {
  width: 4rem;
}
.table-user-list-link i {
  cursor: pointer;
  color: #3396fc;
}
.table-user-list-link i:hover {
  color: #217cd9;
}

.table-user-list-check,
.table-user-list-id {
  width: 8rem;
}

.table-user-list-buttons {
  width: 12rem;
}

.table-user-detail {
  margin: 1.5rem auto 3rem;
  border-top: 1px solid #dee1e7;
}
.table-user-detail th,
.table-user-detail td {
  padding: 1.25rem 1.75rem;
}
.table-user-detail th {
  width: 14rem;
  background-color: rgba(222, 232, 251, 0.35);
  font-size: 1.2rem;
}
.table-user-detail td p {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ced4da;
}
.table-user-detail td p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.table-user-detail td span {
  display: inline-block;
  margin: 0 0.5rem;
  padding: 0.25rem 0.75rem;
  background-color: rgba(36, 71, 137, 0.05);
}
.table-user-detail td span:first-child {
  margin-left: 0;
}
.table-user-detail-department {
  position: relative;
  padding-right: 4rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ced4da;
}
.table-user-detail-department::after {
  position: absolute;
  bottom: 2rem;
  right: 0;
  margin-top: -2rem;
  content: '\f2ed';
  font-size: 2rem;
  font-family: 'Font Awesome 5 Free';
}

.user-detail-buttons {
  margin: 1.5rem auto 3rem;
  display: flex;
  flex-wrap: wrap;
}
.user-detail-buttons .btn {
  flex: 1;
  font-weight: 500;
  font-size: 1.3rem;
}

.login {
  height: calc(100vh - 60px);
}
.login-alert {
  position: absolute;
  left: 0;
  right: 0;
  margin: 1rem auto;
  padding: 1rem 2rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  z-index: 1;
}
.login-card {
  margin-top: -7rem;
  border-radius: 1rem;
}
.login-card .card-body {
  padding: 4rem;
}
.login-link-text {
  margin-top: 2rem;
  text-align: center;
}
.login-link-text a {
  text-decoration: none;
  color: #244789;
}
.login .form-control {
  height: 4.2rem;
}

.abstract-department .department-list-title {
  padding-left: 3rem;
  background-image: url('~images/icon-network.svg');
  background-position: left center;
  background-size: 1.6rem;
  background-repeat: no-repeat;
}

.abstract-manager {
  margin-top: 3rem;
  padding-bottom: 0.75rem;
}
.abstract-manager .department-list-title {
  padding-left: 3rem;
  background-image: url('~images/icon-chair.svg');
  background-position: left center;
  background-size: 1.6rem;
  background-repeat: no-repeat;
}

.table-report th,
.table-report td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
  width: 18%;
}
.table-report th:first-child,
.table-report td:first-child {
  padding-left: 1rem;
}
.table-report th:last-child,
.table-report td:last-child {
  padding-right: 1rem;
}

.table-report th {
  height: 4rem;
  text-align: center;
}

.table-report td {
  height: 6rem;
  text-align: center;
}
.table-report td:first-child {
  width: 28%;
  font-weight: 700;
  text-align: left;
}
.table-report td a {
  color: #244789;
}
.table-report td.alert {
  background-color: #f8d7da;
}
.table-report td.alert a {
  color: #842029;
  font-weight: 500;
}

.table-report-target th,
.table-report-target td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
}
.table-report-target th:first-child,
.table-report-target td:first-child {
  padding-left: 1rem;
}
.table-report-target th:last-child,
.table-report-target td:last-child {
  padding-right: 1rem;
}

.table-report-target th {
  height: 4rem;
  text-align: center;
}

.table-report-target td {
  height: 6rem;
  text-align: center;
}
.table-report-target td a {
  color: #244789;
}
.table-report-target td.alert {
  background-color: #f8d7da;
}
.table-report-target td.alert a {
  color: #842029;
  font-weight: 500;
}

.table-report-target-factor span {
  display: inline-block;
  margin: 0 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: rgba(36, 71, 137, 0.05);
}

.table-report-target-stress {
  width: 6rem;
  border-right: 2px solid transparent;
}
.table-report-target-stress.alert {
  background-color: #f8d7da;
  color: #842029;
}
.table-report-target-stress.warning {
  background-color: #fff3cd;
  color: #664d03;
}
.table-report-target-stress.normal {
  background-color: rgba(222, 232, 251, 0.75);
  color: #244789;
}

.table-report-target-department {
  max-width: 8rem;
  font-size: 1.2rem;
}

.table-history th,
.table-history td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
  width: 25%;
}
.table-history th:first-child,
.table-history td:first-child {
  padding-left: 1rem;
}
.table-history th:last-child,
.table-history td:last-child {
  padding-right: 1rem;
  width: 50%;
}

.table-history th {
  height: 4rem;
}

.table-history td {
  height: 6rem;
}

.table-organize th,
.table-organize td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
}
.table-organize th:first-child,
.table-organize td:first-child {
  padding-left: 1rem;
}
.table-organize th:last-child,
.table-organize td:last-child {
  padding-right: 1rem;
}

.table-organize th {
  height: 4rem;
  text-align: center;
}

.table-organize td {
  height: 6rem;
  text-align: center;
}
.table-organize td:nth-child(4) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.table-organize td i {
  display: block;
  margin-right: 1.4rem;
  cursor: pointer;
  color: #3396fc;
}
.table-organize td i:hover {
  color: #217cd9;
}

.table-graph {
  width: 48rem;
}
.table-graph-wrapper {
  display: flex;
}
.table-graph th,
.table-graph td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
  height: 2rem;
  text-align: left;
}
.table-graph th:nth-child(1),
.table-graph td:nth-child(1) {
  padding-left: 1rem;
  width: 7rem;
}
.table-graph th:nth-child(2),
.table-graph td:nth-child(2) {
  width: 11rem;
  padding-left: 1rem;
}
.table-graph th:nth-child(3),
.table-graph td:nth-child(3) {
  padding-right: 1rem;
  width: calc(100% - 18rem);
}
.table-graph th {
  font-size: 1.2rem;
}
.table-graph tr:nth-child(2n-1) td:nth-child(1) {
  text-align: center;
}
.table-graph tr:nth-child(2n-1) td:nth-child(2),
.table-graph tr:nth-child(2n-1) td:nth-child(3) {
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  border-color: transparent;
}
.table-graph tr:nth-child(2n) td {
  padding-top: 0.25rem;
  padding-bottom: 1rem;
}
.table-graph i {
  margin-left: 1rem;
  cursor: pointer;
  color: #3396fc;
}
.table-graph i:hover {
  color: #217cd9;
}

.search-list input[type='text'] {
  width: calc(100% - 10rem);
  max-width: 36rem;
  margin-right: 1rem;
}

.search-list select {
  width: 100%;
  max-width: 36rem;
}

.search-list-condition {
  width: 9rem;
  color: #a1a6ab;
  font-size: 1.3rem;
}

.search-list-term {
  display: inline-block;
  width: 4rem;
  text-align: center;
}

.search-list-text {
  display: inline-block;
  margin: 0 1.5rem;
  text-align: center;
}
.search-list-text span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 1.6rem;
}

.table-advice-list th,
.table-advice-list td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
}
.table-advice-list th:first-child,
.table-advice-list td:first-child {
  padding-left: 1rem;
}
.table-advice-list th:last-child,
.table-advice-list td:last-child {
  padding-right: 1rem;
}

.table-advice-list thead th {
  padding-bottom: 1rem;
}

.table-advice-list td {
  height: 6rem;
}
.table-advice-list td:nth-child(3) span {
  font-weight: 500;
}
.table-advice-list td:nth-child(5) span::before {
  content: '\f508';
  margin-right: 1rem;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
}

.table-advice-list-check,
.table-advice-list-link {
  width: 3rem;
}
.table-advice-list-check i,
.table-advice-list-link i {
  cursor: pointer;
  color: #3396fc;
}
.table-advice-list-check i:hover,
.table-advice-list-link i:hover {
  color: #217cd9;
}

.table-advice-list-buttons {
  width: 14rem;
}
@media (min-width: 992px) {
  .table-advice-list-buttons {
    width: 26rem;
  }
}

.table-advice-list-boss td {
  border-top: 2px solid transparent;
  height: auto;
  padding-top: 0;
  padding-bottom: 1rem;
}

.staff-add-control {
  display: inline-block;
  margin: 1rem auto;
  cursor: pointer;
}
.staff-add-control:hover {
  opacity: 0.9;
}
.staff-add-control i {
  margin-right: 1rem;
  transition: all 0.3s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.staff-add-control.active i {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.staff-add-content {
  margin-bottom: 2rem;
}
.staff-add-content-header {
  display: flex;
  border-radius: 0.35rem;
}
.staff-add-content-body {
  margin-top: 1rem;
}

.advice-log {
  position: relative;
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 1rem 3rem 1rem 1rem;
}
.advice-log-time {
  width: 4rem;
  text-align: right;
}
.advice-log-number {
  margin-left: auto;
  font-size: 1.6rem;
  font-weight: 500;
}
.advice-log-download {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 0.35rem;
  transition: all 0.15s ease-in-out;
  border: 1px solid transparent;
}
.advice-log-download:hover {
  border-color: rgba(51, 150, 252, 0.75);
}
.advice-log-download::before {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  content: '\f358';
  font-size: 1.6rem;
  font-family: 'Font Awesome 5 Free';
  color: #3396fc;
}

.advice-total {
  display: flex;
  align-items: center;
  height: 5rem;
}
.advice-total input {
  color: #4a5261;
}
.advice-total input[type='checkbox'] {
  margin: auto 1rem;
  width: 2.2rem;
}
.advice-total input[type='text'] {
  margin-left: auto;
  width: calc(100% - 13rem);
  border: none;
  background: none;
  text-align: right;
  font-size: 1.6rem;
  font-weight: 500;
  pointer-events: none;
}
.advice-total label {
  width: 6rem;
}
.advice-total span {
  position: relative;
  top: 0.1rem;
  margin: auto 1rem;
}

span.factor-a {
  background-color: #a1a7ab !important;
  border-radius: 0.25rem;
  color: #fff !important;
}

span.factor-b {
  background-color: #e0adb6 !important;
  border-radius: 0.25rem;
}

span.factor-c {
  background-color: #1b8ac0 !important;
  border-radius: 0.25rem;
  color: #fff !important;
}

span.factor-d {
  background-color: #eee792 !important;
  border-radius: 0.25rem;
}

span.factor-e {
  background-color: #93c3db !important;
  border-radius: 0.25rem;
}

.factor-wrap.factor-a span:nth-child(1) {
  background-color: #a1a7ab !important;
  border-radius: 0.25rem;
  color: #fff !important;
}

.factor-wrap.factor-b span:nth-child(2) {
  background-color: #e0adb6 !important;
  border-radius: 0.25rem;
  color: #fff !important;
}

.factor-wrap.factor-c span:nth-child(3) {
  background-color: #1b8ac0 !important;
  border-radius: 0.25rem;
  color: #fff !important;
}

.factor-wrap.factor-d span:nth-child(4) {
  background-color: #eee792 !important;
  border-radius: 0.25rem;
}

.factor-wrap.factor-e span:nth-child(5) {
  background-color: #93c3db !important;
  border-radius: 0.25rem;
  color: #fff !important;
}

.result-individual {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  overflow-x: auto;
  background-color: #fef4ef;
}
.result-individual .result-box h2::before,
.result-individual .result-head {
  background-color: #e73;
}
.result-individual .result-box-title h2 {
  border-color: #e73;
}
.result-individual .result-box-content h3::before,
.result-individual .result-box-title .subtext {
  color: #e73;
}
.result-individual .result-box-content table th {
  background-color: #fef4ef;
}
.result-individual table td i {
  color: #e73;
}

.result-personnel {
  overflow-x: auto;
  background-color: #f4eeff;
}
.result-personnel .result-box h2::before,
.result-personnel .result-head {
  background-color: #8844ff;
}
.result-personnel .result-box-title h2 {
  border-color: #8844ff;
}
.result-personnel .result-box-content h3::before,
.result-personnel .result-box-title .subtext {
  color: #8844ff;
}
.result-personnel .result-box-content table th {
  background-color: #f4eeff;
}
.result-personnel table td i {
  color: #8844ff;
}

.result-boss {
  overflow-x: auto;
  background-color: #eaffec;
}
.result-boss .result-box h2::before,
.result-boss .result-head {
  background-color: #4bd354;
}
.result-boss .result-box-title h2 {
  border-color: #4bd354;
}
.result-boss .result-box-content h3::before,
.result-boss .result-box-title .subtext {
  color: #4bd354;
}
.result-boss .result-box-content table th {
  background-color: #eaffec;
}
.result-boss table td i {
  color: #4bd354;
}
/* .result-boss .result-logo {
  top: -9rem;
} */
.result-boss .result-container {
  margin: 10rem auto 7.5rem;
}

.result-interview {
  overflow-x: auto;
  background-color: #eef7ff;
}
.result-interview .result-box h2::before,
.result-interview .result-head {
  background-color: #4af;
}
.result-interview .result-box-title h2 {
  border-color: #4af;
}
.result-interview .result-box-content h3::before,
.result-interview .result-box-title .subtext {
  color: #4af;
}
.result-interview .result-box-content table th {
  background-color: #eef7ff;
}
.result-interview table td i {
  color: #4af;
}
/* .result-interview .result-logo {
  top: -9rem;
} */
.result-interview .result-container {
  margin: 10rem auto 7.5rem;
}

.result-advice {
  background-color: #e2deff;
}
.result-advice .result-box h2::before,
.result-advice .result-head {
  background-color: #160793;
}
.result-advice .result-box-title h2 {
  border-color: #160793;
}
.result-advice .result-box-content h3::before,
.result-advice .result-box-title .subtext {
  color: #160793;
}
.result-advice .result-box-content table th {
  background-color: #e2deff;
}
.result-advice table td i {
  color: #160793;
}

.result-page {
  position: relative;
  page-break-before: always;
  page-break-after: always;
}

.result-logo {
  position: absolute;
  top: -9rem;
  right: 0;
  padding: 1rem 2rem;
}
.result-logo img {
  width: 26rem;
}
@media print {
  .result-logo img {
    width: 24rem;
  }
}
.result-logo p {
  margin: 0.25rem 0;
  padding: 0;
  font-size: 1rem;
  text-align: right;
}

.result-container {
  width: 1440px;
  margin: 5rem auto 2rem;
  background-color: #fff;
  border-radius: 2rem;
  text-align: center;
}
@media print {
  .result-container {
    margin-bottom: 0;
  }
}

.result-head {
  position: relative;
  top: -2.4rem;
  display: inline-block;
  padding: 1rem 3rem;
  border-radius: 5rem;
  color: #fff;
}
@media print {
  .result-head {
    color: #4a5261;
  }
}
.result-head h1 {
  display: inline-block;
  margin: 0 0 0.6rem;
  font-size: 1.4rem;
  line-height: 1;
}
.result-head p {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
}
.result-head p span {
  margin-left: 1rem;
  font-size: 1.2rem;
}

.result-body {
  position: relative;
  margin: -3rem 0 0;
  padding: 2rem 2rem 3rem;
  text-align: left;
}

.result-box-title h2 {
  position: relative;
  padding: 0.75rem 1rem 0.85rem 3rem;
  border: 1px solid;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
}
@media print {
  .result-box-title h2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.result-box-title h2::before {
  position: absolute;
  left: 0.2rem;
  top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  padding-bottom: 0.3rem;
  border-radius: 50%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
}
@media print {
  .result-box-title h2::before {
    width: 2rem;
    height: 2rem;
    background: none;
    color: #4a5261;
  }
}
.result-box-title h2.title01::before {
  content: '1';
}
.result-box-title h2.title02::before {
  content: '2';
}
.result-box-title h2.title03::before {
  content: '3';
}
.result-box-title h2.title04::before {
  content: '4';
}
.result-box-title h2.title05::before {
  content: '5';
}
.result-box-title h2.title06::before {
  content: '6';
}

.result-box-title .subtext {
  margin: 0 0 0.5rem;
  padding: 0 2rem;
  font-size: 1rem;
}

.result-box-content {
  padding: 0.25rem 2rem;
  font-size: 1.2rem;
  line-height: 1.4;
}
@media print {
  .result-box-content {
    line-height: 1.1;
  }
}
.result-box-content h3 {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 2rem;
  font-size: 1.2rem;
}
@media print {
  .result-box-content h3 {
    font-size: 1.1rem;
  }
}
.result-box-content h3::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\f0c8';
  font-size: 1.4rem;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.result-box-content h4 {
  margin-top: 1rem;
  font-size: 1.2rem;
}
@media print {
  .result-box-content h4 {
    font-size: 1.1rem;
  }
}
.result-box-content p {
  font-size: 1.1rem;
}
.result-box-content p.font-lg {
  font-size: 1.25rem;
}
@media print {
  .result-box-content p.font-lg {
    font-size: 1.1rem;
  }
}
.result-box-content table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1.5rem;
}
.result-box-content table.table-auto {
  width: auto;
}
.result-box-content table thead th {
  text-align: center;
}
.result-box-content table th,
.result-box-content table td {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
}
.result-box-content table th {
  font-weight: 400;
}
.result-box-content table th.title {
  width: 8rem;
}
.result-box-content table th.narrow {
  width: 6rem;
}
.result-box-content table td.focus {
  background-color: #f4eeff;
}
.result-box-content ol li {
  margin-bottom: 1rem;
}
.result-box-content ol .exsample {
  margin: 0.5rem;
  padding-left: 1rem;
  border-left: 1px solid #4af;
}
.result-box-content-focus {
  margin-bottom: 1rem;
}
.result-box-content-focus .title {
  margin-right: 0.5rem;
  padding-right: 1.5rem;
  background-image: url('~images/icon-caret-right.svg');
  background-position: right 0.4rem;
  background-size: 1rem;
  background-repeat: no-repeat;
  font-size: 1.2rem;
}
@media print {
  .result-box-content-focus .title {
    font-size: 1.1rem;
  }
}
.result-box-content-focus .text {
  border-bottom: 1px solid #4a5261;
  font-size: 1.4rem;
  line-height: 1.6;
}
@media print {
  .result-box-content-focus .text {
    font-size: 1.1rem;
  }
}
.result-box-content-history {
  margin-top: 1.5rem;
}
.result-box-content-history dl {
  display: flex;
  margin: 0;
  border-top: 1px solid #ced4da;
  font-size: 1.1rem;
}
.result-box-content-history dl:last-child {
  border-bottom: 1px solid #ced4da;
}
.result-box-content-history dt {
  width: 10rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-weight: 400;
}
.result-box-content-history dd {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
  border-left: 0.5rem solid;
}
.result-box-content-history dd.hyper-stress {
  border-color: red;
}
.result-box-content-factor {
  margin: 1rem 0;
}
.result-box-content-factor .title {
  font-weight: 500;
}
.result-box-content-factor .factor {
  margin-left: 0.5rem;
  padding: 0.125rem 0.5rem 0.25rem;
}
@media print {
  .result-box-content-factor .factor {
    color: #4a5261 !important;
  }
}

.chart {
  width: 650px;
}

.stress-graph {
  width: 45rem;
}

.nurture-graph {
  width: 120rem;
}

.node {
  font-size: 1.2rem;
  font-weight: 700;
}

.link {
  fill: none;
  stroke: #ced4da;
  stroke-width: 0.2rem;
}

div.division-select-level {
  height: 250px;
  overflow: scroll;
}
