.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.center-screen {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-primary {
  color: white;
  &:hover {
    color: white;
    background-color: #0b5ed7;
  }
}
span.score-range {
  font-size: 15px;
  margin: 0 5px;
}
.pagy-bootstrap-nav {
  .pagination {
    .page-link {
      color: #2196f3 !important;
      padding: 6px 12px !important;
    }
    .page-item.active {
      .page-link {
        color: white !important;
        background-color: #2196f3;
        border-color: #2196f3;
      }
    }
  }
}
.no-event {
  pointer-events: none;
}
.no-margin-left {
  margin-left: 0 !important;
}
#employee-details-modal {
  #stress-test-info {
    span.stress-test-info {
      margin-bottom: 10px;
    }
  }
}
.icon-loading-center {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.divisions-group {
  position: relative;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
  padding-bottom: 10px;
  .delete-employee-divisions {
    position: absolute;
    font-size: 20px;
    bottom: 20px;
    right: -22px;
    cursor: pointer;
    color: black;
  }
}
.no-data {
  text-align: center;
  padding: 10px;
  margin: 0;
  color: #6c757d;
}

.no-event {
  pointer-events: none;
}

.department-manager-id {
  width: 35rem;
}

.mt-3rem {
  margin-top: 3rem;
}

.form-control {
  &-error-message {
    width: 23.5rem;
  }
}
.result-box-content-history dd {
  flex: 1;
}

.user-information {
  flex-direction: column;

  .user {
    padding-bottom: 3px;

    &:first-child {
      padding-top: 12px;
    }

    &:last-child {
      padding-bottom: 12px;
    }
  }
}

.employee-detail-modal {
  z-index: 1056;
}
.dropdown-menu.active {
  left: unset;
}
.modal-responsive {
  max-width: 90vw;
}
.modal-fit-content {
  min-width: 800px;
  width: fit-content;
  margin: auto;
}

.factor-wrap {
  span {
    width: 30px;
  }

  .ffs-score-reliability {
    width: 58px;
  }

  .ffs-score {
    margin: 0 0.3rem;
  }
}
.ffs-type-wrap {
  span.ffs-score-a {
    width: 43px;
  }
}

.ffs-type-wrap span {
  width: 30px;
}

.background-transparent {
  background-color: transparent !important;
}

// Dendrogram
@import url('./dendrogram.scss');

.dendrogram-tbody {
  border-top: none !important;
}

.table-user-list-department {
  .border-bottom {
    border-bottom: 1px solid #b1b1ac !important;
    padding-bottom: 1px;
  }
}

.vertical-centering {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#loading-wrapper {
  width: 50px;
}

.full-height {
  height: 100%;
}

.table-user-list {
  .name {
    background-color: transparent;
  }
}
.sign-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 12px);
  &.wrapper-score-type {
    width: 50px;
  }
  &.wrapper-score-range {
    width: 60px;
  }
}
.user-information {
  border-bottom: 2px solid white;
  margin: auto;
}

.w-22-rem {
  width: 22rem;
}

.result-unauthorized {
  background-color: #ffffff;
}
.result-unauthorized .result-box h2::before,
.result-unauthorized .result-head {
  background-color: rgb(147, 145, 144);
}
#unauthorized_stress_check {
  .result-head {
    top: 4rem !important;
    left: -13rem !important;
  }
}
#employee_score_chart {
  justify-content: center;
}
.form-switch .form-check-input {
  width: 3em;
}
.form-switch {
  float: right;
  display: flex;
  .form-check-label {
    margin-right: 50px;
  }
  #flexSwitchCheckDefault:hover {
    background-color: #d1d1d1;
    border-color: #efefef;
  }
  #flexSwitchCheckDefault:checked {
    background-color: #08b7a9;
    border-color: #22b9a7;
  }
  #flexSwitchCheckDefault:checked:hover {
    background-color: #25a59a;
    border-color: #4fd2c2;
  }
}
.highcharts-scatter-series > .highcharts-data-label {
  display: none;
}
#chart-groups-modal {
  .modal-body {
    padding: 0px 1rem 1rem 1rem;
  }
}
.employee-groups-division-level {
  width: 490px;
  height: 250px;
  overflow: scroll;
}
.employees-division-table {
  td {
    border-top: 5px solid white;
    border-bottom: 5px solid white;
    margin-bottom: 5px;
  }
  td:first-child {
    width: 30px;
  }
  th:first-child {
    width: 70px;
    display: flex;
    border: hidden;
    p {
      font-weight: 100;
    }
  }
}
.table-content {
  padding: 0px 50px;
}
.scroll-x {
  overflow-x: scroll;
}

.export_pdf_alert_text {
  color: #838383;
}

@media only screen and (max-width: 1400px) {
  .table-organize {
    min-width: 1200px;
    thead {
      tr:nth-child(1) th:nth-child(6) {
        width: 100%;
      }
      tr:nth-child(2) th {
        width: 10%;
      }
    }
  }
}

.text-break-spaces {
  white-space: break-spaces !important;
}

.w-10 {
  width: 10% !important;
}
.user-information {
  position: relative;
  .advice-relationships-trainee-rbtn,
  .advice-relationships-traineer-rbtn {
    position: absolute;
    left: 20px;
    height: 20px;
    width: 20px;
  }
}

.copyright {
  a {
    color: #72777a;
    text-decoration: none !important;
  }

  .link-privacy {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  a:hover {
    color: rgb(199, 160, 98);
    background-color: transparent;
    text-decoration: underline;
  }

  a:visited {
    color: rgb(59, 63, 75);
    background-color: transparent;
    text-decoration: none;
  }

  a:active {
    color: yellow;
    background-color: transparent;
    text-decoration: underline;
  }
}

.user-information {
  position: relative;
  .advice-relationships-trainee-rbtn {
    position: absolute;
    left: 20px;
    height: 20px;
    width: 20px;
  }
}
.modal-fit-content {
  min-width: 800px;
  width: fit-content;
  margin: auto;
}

#import-data-info {
  margin-block-start: 150px;

  .content-import-data-failed {
    max-width: 100%;
    max-height: 300px;
    margin-inline-start: 100px;
    margin-inline-end: 100px;
    padding-inline-start: 10px !important;
    p {
      text-align: left;
    }
  }
  .btn-close-modal:hover {
    background-color: #e7e7e7;
  }

  .modal-fit-content {
    border-radius: 0.3rem;
    box-shadow: 0 0 5rem rgba(0, 0, 0, 0.25);
    border: none;
  }

  .btn-close-modal {
    min-width: 0rem !important;
    background: #d0d0d0;
    margin-inline-end: 10px;
    border-color: #ced4da;
    border-radius: 0.35rem;
  }
}
#loader-data {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 90000;
}

/*Hidden class for adding and removing*/
.lds-dual-ring.hidden {
  display: none;
}

/*Add an overlay to the entire page blocking any further presses to buttons or other elements.*/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  opacity: 1;
  transition: all 0.5s;
}

/*Spinner Styles*/
.lds-dual-ring {
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 5% auto;
  border-radius: 50%;
  border: 6px solid rgb(13, 13, 100);
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.search-group i {
  margin-right: 0px !important;
}

.result-advice-relationship {
  background-color: #e2deff;
  .result-advice {
    padding-top: 5px;
    width: 1440px;
  }
  .result-container {
    width: 1280px;
  }
  .wrapper {
    min-height: calc(100vh - 6rem);
    margin-top: 5rem;
  }
}

.w-55p {
  width: 55% !important;
}

.w-100p {
  width: 100% !important;
}

.w-40p {
  width: 40% !important;
}

.w-60p {
  width: 60% !important;
}

.w-50p {
  width: 50% !important;
}

.w-97dot5p {
  width: 97.5% !important;
}

.w-32dot5p {
  width: 32.5% !important;
}
